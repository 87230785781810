.container {
  max-width: 34em;
  margin-left: auto;
  margin-right: auto;
}

canvas {
  vertical-align: bottom;
}

h1 {
  font-size: 3em;
  margin-top: 0;
}

p {
  font-size: 1.25em;
}

a {
  color: #FF562F;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.25s ease-out;
  &:hover {
    color: #C6291A;

  }
}

hr {
  width: 10%;
  margin: 6em auto;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #171718;
    color: #F6F4F7;
  }
  a {
    color: #FD4A52;
    &:hover {
      color: #773054;
    }
  }
}